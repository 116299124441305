/** @jsx jsx */

import { Box, Button, jsx, Spinner } from "theme-ui";
import React, { useEffect, useRef, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";
import Seo from "../components/seo";
import logo from "../../static/imgs/heartfulness-white-512.png";

let cardFooter;
let cardHeader;
let custom;
const fundNameList = [
  "General Donation to SRCM, USA",
  "General Donation to SMSF, USA",
  "General Donations to HI, USA",
  "General Donations to HI, USABHYASIS",
  "General Donation to HI, USA - Recurring",
  "General Donation to SMSF, USA - Recurring",
  "General Donation to SRCM, USA - Recurring",
  "Heartfulness Retreat Center(SPURS) maintenance Fund - Recurring",
  "Dollar a day to HI, USA - Recurring",
  "Thanks Giving Day - USA",
];

const AuthorizeDotNetIframe = () => {
  const [trkId] = useQueryParam("trkId", StringParam);
  const [donationAmount] = useQueryParam("amount", StringParam);
  const [donationCurrency] = useQueryParam("currency", StringParam);
  const [fundName] = useQueryParam("fundName", StringParam);
  const [sessionText, setSessionText] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessgaeText, setErrorMessage] = useState("");
  const [keys, setKeys] = useState({ apiLoginId: "", iframeClientKey: "" });
  const [recurringPaymentAction] = useQueryParam(
    "recurringPaymentAction",
    StringParam
  );
  const [isMobileSite] = usePersist("isMobileSite", "");
  const isApiCalledRef = useRef(false);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            authorizeDotNetMakePaymentUri
            authorizeDotNetRedirectAckUri
            authorizeDotNetPaymentGatewayLib
            authorizeDotNetSrcmClientKey
            authorizeDotNetSmsfClientKey
            authorizeDotNetHiClientKey
            authorizeDotNetSrcmApiLoginKey
            authorizeDotNetSmsfApiLoginKey
            authorizeDotNetHiApiLoginKey
            gatsbyDonationRecurringServiceBaseUri
          }
        }
      }
    `
  );

  const {
    authorizeDotNetMakePaymentUri,
    authorizeDotNetRedirectAckUri,
    authorizeDotNetPaymentGatewayLib,
    authorizeDotNetSrcmClientKey,
    authorizeDotNetSmsfClientKey,
    authorizeDotNetHiClientKey,
    authorizeDotNetSrcmApiLoginKey,
    authorizeDotNetSmsfApiLoginKey,
    authorizeDotNetHiApiLoginKey,
    gatsbyDonationRecurringServiceBaseUri,
  } = site.siteMetadata;

  useEffect(() => {
    const keyData = {};
    if (fundNameList.includes(fundName)) {
      if (
        fundName === "General Donation to SRCM, USA" ||
        fundName === "General Donation to SRCM, USA - Recurring"
      ) {
        Object.assign(keyData, {
          apiLoginId: authorizeDotNetSrcmApiLoginKey,
          iframeClientKey: authorizeDotNetSrcmClientKey,
        });
      } else if (
        fundName === "General Donation to SMSF, USA" ||
        fundName === "General Donation to SMSF, USA - Recurring" ||
        fundName ===
          "Heartfulness Retreat Center(SPURS) maintenance Fund - Recurring"
      ) {
        Object.assign(keyData, {
          apiLoginId: authorizeDotNetSmsfApiLoginKey,
          iframeClientKey: authorizeDotNetSmsfClientKey,
        });
      } else if (
        fundName === "General Donations to HI, USA" ||
        fundName === "General Donations to HI, USABHYASIS" ||
        fundName === "General Donation to HI, USA - Recurring" ||
        fundName === "Dollar a day to HI, USA - Recurring" ||
        fundName === "Thanks Giving Day - USA"
      ) {
        Object.assign(keyData, {
          apiLoginId: authorizeDotNetHiApiLoginKey,
          iframeClientKey: authorizeDotNetHiClientKey,
        });
      }
    }

    setKeys(keyData);
  }, [
    fundName,
    authorizeDotNetSrcmApiLoginKey,
    authorizeDotNetSrcmClientKey,
    authorizeDotNetSmsfApiLoginKey,
    authorizeDotNetSmsfClientKey,
    authorizeDotNetHiApiLoginKey,
    authorizeDotNetHiClientKey,
  ]);

  function createRequest(url, data, method) {
    try {
      const reqParam = {
        method,
        headers: new Headers({ "Content-type": "application/json" }),
      };
      if (method === "POST") {
        Object.assign(reqParam, {
          body: JSON.stringify(data),
        });
      }

      return fetch(url, reqParam)
        .then((res) => res.ok && res.json().then((json) => json))
        .catch((err) => {
          return `${err}url:${url}`;
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("error", e);
    }
    return null;
  }

  const makeRedirectCall = (resp) => {
    try {
      const {
        amount,
        currency,
        paymentTransactionId,
        paymentTrackingId,
        otherDetail,
      } = resp;
      const redirectData = {
        trackingId: paymentTrackingId,
        currency,
        amount,
        transactionId: paymentTransactionId || "null",
        fundName: otherDetail.fundName,
      };

      const url = new URL(authorizeDotNetRedirectAckUri);
      url.search = new URLSearchParams(redirectData).toString();
      const method = "GET";
      createRequest(url, "", method)
        .then((res) => {
          if (res && (res.successUrl || res.failureUrl)) {
            window.location.href = res.successUrl || res.failureUrl;
          }
        })
        .catch((err) => {
          return `${err}redirect call failed url:${url}`;
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Error", e);
    }
  };

  function responseHandler(response) {
    /* eslint-disable no-console */
    console.log("Handler Initiated");
    console.log(new Date());
    /* eslint-enable no-console */
    setIsLoading(true);
    try {
      isApiCalledRef.current = !isApiCalledRef.current;
      if (isApiCalledRef.current === true) {
        if (response.messages.resultCode === "Error") {
          setIsLoading(false);
          let i = 0;
          while (i < response.messages.message.length) {
            setErrorMessage(response.messages.message[i].text);
            i += 1;
          }
        } else {
          const postData = {
            currency: donationCurrency,
            amount: donationAmount,
            trackingId: trkId,
            otherDetail: {
              fundName,
            },
            opaqueData: {
              dataDescriptor: response.opaqueData.dataDescriptor,
              dataValue: response.opaqueData.dataValue,
            },
            paymentGatewayAccount: { name: "authorizenet_donation" },
          };
          // eslint-disable-next-line no-console
          console.log("Payload Generated");
          const method = "POST";
          if (recurringPaymentAction) {
            Object.assign(postData, {
              customerDetail: { opaqueData: postData.opaqueData },
              otherDetail: {
                hostUrl: `${window.location.origin}`,
                pageUrl: `/updateRecurring/`,
              },
            });

            delete postData.currency;
            delete postData.amount;
            delete postData.opaqueData;

            setTimeout(() => {
              createRequest(
                `${gatsbyDonationRecurringServiceBaseUri}${recurringPaymentAction}`,
                postData,
                method
              )
                .then((res) => {
                  if (res) {
                    if (res.errorMessage) {
                      setErrorMessage(res.errorMessage);
                      setIsLoading(false);
                    } else if (res.redirectUrl) {
                      window.location.href = res.redirectUrl;
                    } else {
                      navigate(`/`);
                    }
                  }
                })
                .catch((err) => {
                  return `${err} subsrcibe failed url:${""}`;
                });
            }, 5000);
          } else {
            // eslint-disable-next-line no-console
            console.log("Trigger MakePayments API");
            setTimeout(() => {
              createRequest(authorizeDotNetMakePaymentUri, postData, method)
                .then((res) => res && makeRedirectCall(res))
                .catch((err) => {
                  return `${err}make payment failed url:${authorizeDotNetMakePaymentUri}`;
                });
            }, 5000);
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  useEffect(() => {
    window.responseHandler = responseHandler;
  });

  const handleClose = () => {
    if (isMobileSite) {
      const url = new URL(window.location.origin + window.location.pathname);
      url.searchParams.set("status", "failure");
      window.location.href = url;
    } else {
      navigate("/");
    }
  };

  const addHeaderFooter = (key, bg, height) => {
    let element = "";
    const headerContainer = `<div style="height:100%;align-items:center;padding-left:20px;display:flex;justify-content: space-between;">
        <img src=${logo} alt="logo" height="200%" />
        <div id="back-btn" style="cursor:pointer;color:#ffff;margin-right: 20px;">Back</div>
        </div>`;
    const footerContainer = `<div style="height:100%;text-align:center;padding-right:20px;font-size:12px">
        Secured by AcceptUi
        </div>`;

    element = document.createElement("div");
    element.setAttribute("id", key);
    element.style.backgroundColor = bg;
    element.style.maxHeight = height;
    element.innerHTML =
      key === "headerContainer" ? headerContainer : footerContainer;
    return element;
  };

  const addCustom = (key) => {
    const customSection = `
    <div >
        <p style="text-align: center;
        font-size: 1.8rem;
        font-weight: bold;
        color: white;
        padding: 1rem;">
            Amount: ${donationAmount} ${donationCurrency}
        </p>
    </div>`;
    const customElement = document.createElement("div");
    customElement.setAttribute("id", key);
    customElement.innerHTML = customSection;
    return customElement;
  };

  useEffect(() => {
    if (sessionText) {
      return;
    }

    setTimeout(() => {
      const { iframeClientKey, apiLoginId } = keys;
      const acceptContainer = document.getElementById("AcceptUIContainer");
      const acceptBackground = document.getElementById("AcceptUIBackground");
      const headerContainerTag = document.getElementById("headerContainer");
      const footerContainerTag = document.getElementById("footerContainer");
      const customContainerTag = document.getElementById("customContainer");
      if (acceptContainer) {
        acceptBackground.style.backgroundColor = "#7fb17f";
        acceptBackground.style.opacity = 1;
        cardHeader = addHeaderFooter("headerContainer", "#006400", "65px");
        cardFooter = addHeaderFooter("footerContainer", "#ababab", "25px");
        custom = addCustom("customContainer");
        acceptContainer.style.backgroundColor = "#ababab";
        acceptContainer.style.minHeight = "500px";
        acceptContainer.style.top = "35%";
        if (!customContainerTag) {
          acceptBackground.append(custom);
        }
        if (!headerContainerTag) {
          acceptContainer.insertBefore(
            cardHeader,
            acceptContainer.firstElementChild
          );
        }

        if (!footerContainerTag) {
          acceptContainer.appendChild(cardFooter);
        }

        if (iframeClientKey && apiLoginId) {
          acceptContainer.classList.add("show");
          acceptBackground.classList.add("show");
        }
        acceptContainer.style.top = "45%";
        document.getElementById("back-btn").addEventListener("click", () => {
          window.location.href = "/";
        });
      }

      setSessionText(true);
    }, 4000);
  });

  const { iframeClientKey, apiLoginId } = keys;
  if (!apiLoginId || !iframeClientKey) {
    return <div sx={{ display: "none" }}>Fetching Fund name</div>;
  }
  return (
    <React.Fragment>
      <Seo
        script={[
          {
            type: "text/javascript",
            src: authorizeDotNetPaymentGatewayLib,
          },
        ]}
        title="Donation - General Donation - USA"
      />
      <form>
        <button
          style={{ display: "none" }}
          id="acceptUIPayBtn"
          type="button"
          className="AcceptUI"
          data-billingaddressoptions='{"show":true, "required":false}'
          data-apiloginid={apiLoginId}
          data-clientkey={iframeClientKey}
          data-acceptuiformbtntxt="Submit"
          data-acceptuiformheadertxt="Card Information"
          data-paymentoptions='{"showCreditCard": true}'
          data-responsehandler="responseHandler"
        >
          pay
        </button>
      </form>
      <Box
        sx={{
          display: !sessionText || isLoading ? "block" : "none",
          textAlign: "center",
        }}
      >
        <Box>
          <Spinner size={30} /> Authorize.net Secure Payment..
        </Box>
        <Box sx={{ fontSize: "15px" }}>Do not refresh the page.</Box>
      </Box>

      <div
        sx={{
          display: sessionText && !isLoading ? "block" : "none",
          textAlign: "center",
          mt: "30px",
        }}
      >
        <b sx={{ fontSize: "24px" }}>Authorize.net Secure Payment...</b>
        {(!iframeClientKey || !apiLoginId) && (
          <div>Invalid fund, Please contact Admin.</div>
        )}
        {errorMessgaeText && <div>{errorMessgaeText} Try again later.</div>}
        <br />
        <Button
          sx={{
            marginTop: "20px",
            color: "#7fb17f",
            border: "2px solid",
            padding: "5px 20px",
            borderRadius: "5px",
            fontWeight: "bolder",
          }}
          onClick={() => handleClose()}
        >
          Close
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AuthorizeDotNetIframe;
